input.form-control {
  border-color: $light-blue-border;
  height: 36px;
  border-radius: 2px;
}
.inputMask {
  height: 36px;
  border: 1px solid #dce8f2;
  width: 100%;
}
.w-squared-input {
  min-width: 60px;
  max-width: 60px;
  box-sizing: content-box;
  input {
    text-align: center;
    padding: 0;
  }
}

.w-no-squared-input {
  min-width: 60px;
  max-width: 120px;
  box-sizing: content-box;
  input {
    text-align: right;
    padding: 5px;
  }
}

.search-input {
  padding-right: 30px;
}

.search-placeholder {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-control {
  padding-left: 35px;
}

.custom-control-input {
  width: 20px;
  height: 20px;
}

.custom-control-label::after {
  top: 0.25rem;
  left: -35px;
  display: block;
  width: 20px;
  height: 20px;
}

.custom-control-label::before {
  left: -35px;
  width: 20px;
  height: 20px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $green;
  border-color: $green;
}

.custom-checkbox:not(.squared) .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

.custom-radio:not(.squared) .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

.custom-checkbox .custom-control-label::before {
  background-color: $white;
  border-color: $green;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.squared.custom-checkbox .custom-control-label::before {
  border-radius: 2px;
}

.custom-control-input:focus ~ .custom-control-label::before {
  border-color: $green;
  box-shadow: 0 0 0 0.2rem rgba(110, 215, 154, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $green;
}

.custom-switch .custom-control-label::after {
  width: 16px;
  height: 16px;
}

.custom-switch .custom-control-label::before {
  width: 31px;
}

textarea {
  height: 120px;
  border-color: $light-blue-border;
  min-height: 60px;
  padding: 6px 12px;
  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.dark-blue.custom-control-input:checked ~ .custom-control-label::before,
.dark-blue.custom-checkbox .custom-control-label::before,
.dark-blue.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $dark-blue;
}

.dark-blue .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $dark-blue;
  border-color: $dark-blue;
}

.dark-blue .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(17, 44, 75, 0.25);
  border-color: $dark-blue;
}

.border-error {
  border-color: #dc3545 !important;
}