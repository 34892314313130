@import '../../config/constants.scss';

.login-app {
  min-height: 100vh;
  .login-header {
    background-color: $dark-blue;
  }
  .login-form {
    max-width: 300px;
  }
}