.border-top {
  border-top: 1px solid $light-blue-border!important;
}
.border-bottom {
  border-bottom: 1px solid $light-blue-border!important;
}
.border-right {
  border-right: 1px solid $light-blue-border!important;
}
.border-left {
  border-left: 1px solid $light-blue-border!important;
}
.border {
  border: 1px solid $light-blue-border!important;
}

.border-dark-blue {
  border-color: $dark-blue!important;
}

.border-green {
  border-color: $green!important;
}

.border-purple {
  border-color: $purple!important;
}

.border-blue {
  border-color: $blue!important;
}

.border-orange {
  border-color: $orange!important;
}