button {
  touch-action: manipulation;
}
.btn {
  border-radius: 2px;
  line-height: 1.2;
  min-height: 36px;
  touch-action: manipulation;
  &-hidden {
    padding: 0;
  }
  &-green {
    color: #fff;
    background-color: $green;
    border-color: $green;
    &:hover {
      color: #fff;
      background-color: darken($green, 10);
      border-color: darken($green, 10);
    }
  }
  &-blue {
    color: #fff;
    background-color: $blue;
    border-color: $blue;
    &:hover {
      color: #fff;
      background-color: darken($blue, 10);
      border-color: darken($blue, 10);
    }
  }
  &-dark-blue {
    color: #fff;
    background-color: $dark-blue;
    border-color: $dark-blue;
    &:hover {
      color: #fff;
      background-color: darken($dark-blue, 10);
      border-color: darken($dark-blue, 10);
    }
  }
  &-blue-sav {
    color: #fff;
    background-color: $light-blue-sav;
    border-color: $light-blue-sav;
    &:hover {
      color: #fff;
      background-color: darken($light-blue-sav, 10);
      border-color: darken($light-blue-sav, 10);
    }
  }
  &-white {
    color: $light-blue-text;
    background-color: white;
    border-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    &:hover {
      color: $light-blue-text;
      background-color: darken(white, 10);
      border-color: darken(white, 10);
    }
  }
  &-orange {
    color: #fff;
    background-color: $orange;
    border-color: $orange;
    &:hover {
      color: #fff;
      background-color: darken($orange, 10);
      border-color: darken($orange, 10);
    }
  }
  &-yellow {
    color: #fff;
    background-color: $yellow;
    border-color: $yellow;
    &:hover {
      color: #fff;
      background-color: darken($yellow, 10);
      border-color: darken($yellow, 10);
    }
  }
  &-gray {
    color: $light-blue-text;
    background-color: $light-blue;
    border-color: $light-blue;
    &:hover {
      color: #fff;
      background-color: darken($light-blue, 10);
      border-color: darken($light-blue, 10);
    }
  }
  &-square {
    width: 36px;
    height: 36px;
    padding: 0;
  }
  &-md-square {
    width: 42px;
    height: 42px;
    padding: 0;
  }
  &-circle {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    padding: 0;
  }
  &-lg-circle {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    padding: 0;
  }
  &-lg-trash{
    width: 39px;
    height: 39px;
    border-radius: 100%;
    padding: 0;
  }
  &-md-circle {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    padding: 0;
  }
  &:focus {
    box-shadow: none;
  }
}

.btn-outline {
  &-dark-blue {
    color: $dark-blue;
    border-color: $dark-blue;
    &:hover {
      color: $dark-blue;
      border-color: darken($dark-blue, 10);
    }
  }
  &-green {
    color: $green;
    border-color: $green;
    &:hover {
      color: $green;
      border-color: darken($green, 10);
    }
  }
  &-gray {
    color: $light-blue-text;
    border-color: $light-blue-text;
    &:hover {
      color: $light-blue-text;
      border-color: darken($light-blue-text, 10);
    }
  }
}
