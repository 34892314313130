.text-light-blue {
  color: $light-blue-text;
}

.text-blue {
  color: $blue !important;
}

.text-label {
  color: $light-blue-text;
  font-size: 14px;
  &-sm {
    color: $light-blue-text;
    font-size: 12px;
  }
  &-lg {
    color: $light-blue-text;
    font-size: 16px;
  }
}

.text-label-dark {
  color: $dark-blue-text;
  font-size: 14px;
  &-sm {
    color: $dark-blue-text;
    font-size: 12px;
  }
}

.text-label-bold {
  color: $dark-blue-text;
  font-size: 14px;
  font-weight: bold;
  &-sm {
    font-weight: bold;
    color: $dark-blue-text;
    font-size: 12px;
  }
}

.text-agreement {
  color: #202020;
  font-family: Roboto;
  line-height: 13px;
  text-align: left;
  width: 100%;
  padding: 10px;
  font-size: 11px;

  &.mobile {
    height: 150px;
    overflow: auto;
  }
}

.text-tooltip {
  letter-spacing: 1px;
  font-size: 10px;
  background-color: black;
  color: $white;
  border-radius: 0;
  padding: 0;
  text-transform: uppercase;
}

.text-tooltip-solid {
  background-color: white;
  font-size: 16px;
}

.text-tooltip-transparent {
  letter-spacing: 1px;
  font-size: 10px;
  color: $dark-blue-text;
  border-radius: 0;
  text-transform: uppercase;
}

.text-truncate-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-orange {
  color: $orange;
}

.bg-light-blue {
  background: $light-blue;
}

.bg-dark-blue {
  background: $dark-blue;
}

.bg-white {
  background: $white;
}

.bg-dim {
  background-color: rgba(0, 0, 0, 0.04);
}

.text-dark-blue {
  color: $dark-blue;
}

.text-main {
  color: $dark-blue-text !important;
  font-size: 18px;
  font-weight: 800;
}

.text-gray {
  color: $light-blue-text !important;
  font-size: 16px;
  font-weight: 400;
}
.textCount {
  font-size: 13px;
  opacity: 0.8;
  line-height: 14px;
}
.textTitle {
  margin-bottom: 0px;
  font-size: 13px;
  opacity: 0.8;
  line-height: 10px;
}

.text-white {
  color: $white;
}

.text-black {
  color: black !important;
}

.arrow-white {
  .arrow::before {
    border-top-color: white !important;
  }
}

.text-16 {
  font-size: 16px;
}

.text-title {
  font-size: 50px;
  font-weight: 700;
  line-height: 48px;

  &.mobile {
    font-size: 30px;
    text-align: center;
  }
}

.hover-text-green:hover {
  color: $green;
}
