
@import '../../../../config/constants.scss';

.og-btn-outline {
    border: solid 1px $light-blue-text!important;
}

.btn-outlined {
    color: $green !important;
    background-color: transparent !important;
    border: 1px solid $green !important;
  }

  .custom-modal-style {
    width: 80em !important;
    height: 100em;
    // top: 10px;
    // height: 10px;
}

.upload-file-outline {
  color: $light-blue-text !important;
  border: dashed 1px $light-blue-border !important;
  margin: 0px;
  height: 5em;
  cursor: pointer;
  background: $white !important;
}