// /* Custom Scrollbar using CSS */
// .scrollbar {
//   overflow-y: scroll;
// }

// /* scrollbar width */
// .scrollbar::-webkit-scrollbar {
//   width: 5px;
// }

// /* scrollbar track */
// .scrollbar::-webkit-scrollbar-track {
//   background: #eee;
// }

// /* scrollbar handle */
// .scrollbar::-webkit-scrollbar-thumb {
//   border-radius: 1rem;
//   background-color: $light-blue-text;
//   background-image: $light-blue-text;
// }

/* width */
::-webkit-scrollbar {
  width: 11px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $dark-blue-text;
}
::-webkit-scrollbar-track {
  background: #ddedf7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #234874;
  border-radius: 5px;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
} 

.hide-scrollbar::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.hide-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
  
  /* Handle */
.hide-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0px;
}