.recipe-costing.nav-tabs {
  border-bottom: none;
  font-size: 0.85rem;

  .nav-item {
    margin-bottom: 0;

    .nav-link {
      font-weight: 400;
      padding: 1.5rem 2rem;
      border: none;
      text-transform: uppercase;
      color: $light-blue-text;

      &.active {
        color: $dark-blue;
        border-bottom: 2px solid $blue;
        font-weight: 600;
      }
    }
  }
}
