// Constant colors
$darker-blue: #122439;
$dark-blue: #112c4b;
$dark-blue-text:#143357;
$dark-blue-tag-text:#083d77;

$lighter-blue: #f5f9fc;
$light-blue: #eef3f6;
$light-blue-border: #dce8f2;
$light-blue-text: #9BB1C2;
$light-blue-text-bold: #376386;
$fb-light-blue-tag:  #5DADFE;
$light-blue-mkt: #0073c1;
$light-blue-sav: #008CF3;


$white: #ffffff;
$black: #000000;
$blue: #006eba;
$green: #6ed79a;
$orange: #fc745a;
$yellow: #feba5d;
$red: #f95738;
$purple: #8c4ee5;

$imageDesktop: 90px;
$imageMobile: 60px;