@import "../../config/constants.scss";

.header {
  background-color: $light-blue;
  height: 80px;
  width: 100%;
  padding: 0px 12px !important;
  border-bottom: 1px solid $light-blue-border;
  &.is-mobile {
    padding-right: 0 !important;
    padding-left: 0 !important;
    height: 56px;
  }
  h4,
  h5 {
    color: $dark-blue-text !important;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    white-space: pre;
    line-height: 17px;
  }
  .-mt3 {
    margin-top: -3px;
  }

  .cart-icon {
    display: inline-block;
    min-width: 1em;
    padding: 0.2em 0.5em;
    font-size: 12px;
    color: $white;

    .cart-count {
      position: absolute;
      top: -0.5em;
      right: 0.5em;
      font-weight: bold;
      background: $green;
      border-radius: 1em;
      padding-left: 0.7em;
      padding-right: 0.7em;
    }
  }
}

.header-container {
  top: 0;
  left: 0;
  right: 0;
  float: left;
  position: fixed;
  z-index: 1030;
  transition: all ease-in-out 0.2s;

  .order-view {
    width: 100% !important;
    margin: auto;
    .col {
      cursor: pointer;
      &.disabled {
        pointer-events: none;
        h1 {
          color: $dark-blue-text;
          opacity: 0.4;
        }
      }
      &.active {
        h1 {
          color: rgb(0, 109, 186);
        }
        .point {
          background-color: transparent;
          background: url("../../../src/assets/images/car.png") no-repeat;
          width: 50px;
          border-radius: initial;
          height: 13px;
        }
      }
      &.inactive {
        pointer-events: none;
        cursor: pointer;
      }
      .point {
        position: relative;
        margin: auto;
        top: 1px;
        height: 13px;
        width: 13px;
        border-radius: 50%;
        background-color: rgba(66, 139, 202, 0.15);
      }
      .line {
        height: 1px;
        width: 80%;
        background-color: rgba(66, 139, 202, 0.15);
        position: absolute;
        left: 60%;
        top: 8px;
      }
      h1 {
        text-align: center;
        font-size: 10px;
        color: #376386;
        letter-spacing: 1px;
        font-weight: 600;
        opacity: 0.9;
        position: relative;
        top: 22px;
      }
    }
  }
}

.header-relative {
  padding: 0px 12px;
  position: relative;
  overflow: auto;
  &.sidebar-is-open {
    .header-container {
      left: 300px;
      right: -300px;
    }
  }
}
