@import '../../config/constants.scss';

.main-app {
  min-height: 100vh;
  .main-app-content {
    transition: all ease-in-out 0.2s;
    left: 0;
    &.sidebar-is-open {
      left: 300px;
    }
  }
}