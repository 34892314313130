.mask {
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1200;
}

.spinner {
  width: 150px;
  height: 150px;
  margin: 100px auto 0;
  text-align: center;
  position: relative;
  top: 50%;
  margin-top: -16px;
}

.icon-spin {
  height: 3em !important;
  width: 3em !important;
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}